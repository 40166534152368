import React from 'react'
import { Box, Grid } from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import useForm from '@hooks/useForm'
import GenericFormPage from '@templates/Generic/Form'
import {
  TFormAddJumpHost,
  initialValues,
  initialValidation,
  FormAddJumpHost
} from '@components/Form/FormAddJumpHost'
import { createJumpHost } from '@utils/api/jumphosts'
import useApi from '@hooks/useApi'
import { ApiResponse } from '@utils/api'

const HostsJumpHostAddPage = () => {
  const api = useApi<ApiResponse<string>>({
    apiMethod: createJumpHost,
    requestOnMount: false
  })

  const form = useForm<TFormAddJumpHost>({
    initialValues,
    initialValidationRules: initialValidation
  })

  return (
    <GenericFormPage
      maxWidth="md"
      title="Add Jump Host"
      feature="jump_hosts"
      breadcrumbs={[
        {
          title: 'Hosts',
          to: '/hosts/'
        },
        {
          title: 'Jump Hosts',
          to: '/hosts/jump-hosts/'
        },
        {
          title: 'Add Jump Host'
        }
      ]}
      form={form as any}
      api={api}
      successMessage="Jumphost successfully added."
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={api.loading}
            >
              {'Add Jump Host'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'Cancel'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <FormAddJumpHost form={form as any} />
    </GenericFormPage>
  )
}

export default HostsJumpHostAddPage
