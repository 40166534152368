import { Select, TextField } from '@components'
import useApi from '@utils/hooks/useApi'
import useForm, { ValidationRule } from '@utils/hooks/useForm'
import { Skeleton, Stack } from '@mui/material'
import { getCredentials, CredentialsResponse } from '@utils/api'
import { TCreateJumpHost } from '@utils/api/jumphosts'
import React, { ReactElement, useEffect, useState } from 'react'

export type TFormAddJumpHost = TCreateJumpHost
export type TFormEditCredentials = TCreateJumpHost
interface Props {
  form: ReturnType<typeof useForm>
  oneTime?: boolean
  edit?: boolean
}

export const initialValues: TFormAddJumpHost = {
  name: '',
  hostname: '',
  ssh_port: 22,
  credentials_id: ''
}

export const initialValidation: ValidationRule<TFormAddJumpHost> = {
  name: (value) => value.length > 0 && /^[ a-zA-Z0-9_\-]{1,255}$/.test(value),
  hostname: (value) => value.length > 0,
  ssh_port: (value) => typeof value === 'number' && value > 0 && value < 65536,
  credentials_id: (value) => value.length > 0
}

export function FormAddJumpHost({ form, edit = false }: Props): ReactElement {
  const { loading, response } = useApi<CredentialsResponse>({
    // TODO handle errors
    apiMethod: getCredentials
  })

  const [credentialOptions, setCredentialOptions] = useState<
    { value: string; label: string }[]
  >([])

  useEffect(() => {
    if (response && response.data && Array.isArray(response.data)) {
      setCredentialOptions(
        response.data.map(({ id }) => ({
          value: id,
          label: id
        }))
      )
    }
  }, [response])

  useEffect(() => {
    if (credentialOptions.length > 0) {
      form.setFieldValue('credentials_id', credentialOptions[0].value)
    }
  }, [credentialOptions])
  return (
    <Stack spacing={3}>
      <TextField
        required
        id="name"
        label="Name"
        form={form}
        disabled={edit}
        inputProps={edit ? { readOnly: true } : {}}
        helperText="Must be unique, no longer then 255 characters and only contain numbers, letters, spaces, hyphens and underscores."
      />

      <TextField
        required
        id="hostname"
        label="Hostname"
        variant="outlined"
        form={form}
      />

      {!loading && response ? (
        <Select
          id="credentials_id"
          label="Credential"
          form={form}
          options={credentialOptions}
          helperText="Credential used by jump host."
          placeholder="Select credential..."
        />
      ) : (
        <Skeleton variant="rectangular" width="100%" height={78} />
      )}

      <TextField
        type="number"
        id="ssh_port"
        label="SSH Port"
        required
        form={form}
        helperText="SSH port to use for authentication. Port values must only be between 1-65535."
      />
    </Stack>
  )
}
